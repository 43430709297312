import { AxiosPromise } from 'axios';

import { useUser } from '@/hooks/store';
import apiRequest, { ApiRequestArgs } from '@/utils/apiRequest';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ReturnShape = any;

export type SendApiRequest = <T = ReturnShape>(o: ApiRequestArgs) => AxiosPromise<T>;

// TODO: handle an unauthorized req here and revoke any user credentials (on a 401)
export function useApi(): SendApiRequest {
	const { user } = useUser();
	const accessToken = user?.accessToken;

	const makeApiRequest: SendApiRequest = async o => {
		const customHeaders = {
			Authorization: accessToken || '',
			...(o?.customHeaders || {}),
		};
		const requestParamsWithAccessToken = { ...o, customHeaders };
		return apiRequest(requestParamsWithAccessToken);
	};

	return makeApiRequest;
}
